import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
import Link from '../components/Link';
import LeadGenCalculator from '../components/leadgencalculator';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    direction: 'row',
    alignItems: 'center',
  },
}));

function MadeWithLove() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Built with love by the '}
      <MuiLink color="inherit" href="">
        Frickerland Leads
      </MuiLink>
      {' team.'}
    </Typography>
  );
}

export default function App() {
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <Grid container className={classes.rowContainer}>
        <Grid item sm={3}>
          <Box my={4} m={4}>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box my={4} m={4}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              Lead Generation Calculator
            </Typography>
            <br/>
            <LeadGenCalculator/>
            <br/>
            <br/>
            <br/>
            <MadeWithLove />
          </Box>
        </Grid>
        <Grid item sm={3}>
          <Box my={4} m={4}>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

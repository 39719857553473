import React from "react"
import { PropTypes, number } from "prop-types";
import { withStyles } from '@material-ui/styles';
import { styled } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/lab/Slider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';


const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 20,
    label: '20%',
  },
  {
    value: 40,
    label: '40%',
  },
  {
    value: 60,
    label: '60%',
  },
  {
    value: 80,
    label: '80%',
  },
  {
    value: 100,
    label: '100%',
  },
];

function valuetext(value) {
  return `${value}%`;
}

const MyButton = styled(Button)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
});

const styles = theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(5),
  },
  rowContainer: {
    display: 'grid',
    direction: 'column',
    justify: 'space-between',
    alignItems: 'center',
    alignContent: 'space-between',
  },
  inputOutputBoxes: {
    border: '2px solid grey',
    backgroundColor: 'Gainsboro',
  },
  inputPaper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    whiteSpace: 'wrap',
    backgroundColor: 'white',
    marginBottom: theme.spacing(1),
    overflow: 'hidden'
  },
  outputPaper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    whiteSpace: 'wrap',
    minHeight: '9.7rem',
    marginBottom: theme.spacing(1),
  },
  slider: {

  },
  divider: {
    margin: theme.spacing(2, 0),
  },
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={props => {
        onChange({
          target: {
            avgTicketPrice: props.value,
          },
        });
      }}
      thousandSeparator
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};


class LeadGenCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      avgTicketPrice: number,
      numLeadsMonthly: number,
      profitMargin: 60,
      closingRate: 20,
    };
  }

  handleProfitMarginSliderChange = (event, newValue) => {
    this.setState({
      profitMargin: newValue,
    })
  }

  handleClosingRateSliderChange = (event, newValue) => {
    this.setState({
      closingRate: newValue,
    })
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes } = this.props;
    const low = .20
    const mid = .30
    const high = .40
    return (
      <div>
        <Grid container item sm={12} spacing={3} className={classes.inputOutputBoxes} name="Inputs">
          <Grid item sm={12} name="INPUTS">
            <Typography variant="h5" align='center'>Input Data</Typography>
          </Grid>
          <Grid item sm={12} name="AvgTicketPrice">
            <div>
              <Typography gutterBottom>
                Enter the Business' Avg Ticket Price:
              </Typography>
              <Typography variant="h5">
                <input
                  type="text"
                  name="avgTicketPrice"
                  onChange={this.handleInputChange}
                  value={this.state.avgTicketPrice}
                  style={{ fontSize: 20, textAlign: "center" }}
                  id="formatted-numberformat-input"
                  inputComponent={NumberFormatCustom}
                />
              </Typography>
            </div>
          </Grid>
          <Grid item sm={12} name="ProfitMarginSlider">
            <Typography id="discrete-slider" gutterBottom>
              Profit Margin: {this.state.profitMargin}%
              </Typography>
            <Slider
              className={classes.slider}
              defaultValue={60}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks={marks}
              name="profitMargin"
              value={this.state.profitMargin}
              onChange={this.handleProfitMarginSliderChange}
            />
          </Grid>
          <Grid item sm={12} name="ClosingRateSlider">
            <Typography id="discrete-slider" gutterBottom>
              Closing Rate: {this.state.closingRate}%
              </Typography>
            <Slider
              defaultValue={20}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks={marks}
              value={this.state.closingRate}
              onChange={this.handleClosingRateSliderChange}
            />
          </Grid>
          <Grid item sm={12} name="NumberOfLeadsMonthly">
            <div>
              <Typography>
                Enter Estimated Number of Leads Monthly
                  <br />
                <input
                  type="text"
                  name="numLeadsMonthly"
                  value={this.state.numLeadsMonthly}
                  onChange={this.handleInputChange}
                  style={{ fontSize: 20, textAlign: "center" }}
                />
              </Typography>
            </div>
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid container item sm={12} spacing={3} className={classes.inputOutputBoxes} name="Outputs">
          <Grid item sm={12} name="OUTPUTS">
            <Typography variant="h5" align='center'>Output Data</Typography><br />
          </Grid>
          <Grid item sm={4} name="AvgProfitAfterExpenses">
            <Paper className={classes.outputPaper}>
              <Grid container className={classes.rowContainer}>
                <Grid item>
                  <Typography>
                    Avg Profit After Expenses
                      <br />
                  </Typography>
                  <Typography variant="caption">
                    (before marketing expense)
                      <br />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5">
                    {isNaN(
                      (this.state.avgTicketPrice * this.state.profitMargin * .01).toFixed(2)
                    ) ? ("  $" + 0) : ("  $" + (this.state.avgTicketPrice * this.state.profitMargin * .01).toFixed(2))}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item sm={4} name="TotalNewCustomers">
            <Paper className={classes.outputPaper}>
              <Grid container className={classes.rowContainer}>
                <Grid item>
                  <Typography>
                    Total New Customers For Business Owner
                      <br />
                  </Typography>
                </Grid>
                <br />
                <Grid item>
                  <Typography variant="h5">
                    {isNaN(
                      (this.state.numLeadsMonthly * this.state.closingRate * .01).toFixed(0)
                    ) ? (0) : ((this.state.numLeadsMonthly * this.state.closingRate * .01).toFixed(0))}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item sm={4} name="ActualValueOfLead">
            <Paper className={classes.outputPaper}>
              <Grid container className={classes.rowContainer}>
                <Grid item>
                  <Typography>
                    Actual Value of Lead For Business Owner
                    <br />
                  </Typography>
                </Grid>
                <br />
                <Grid item>
                  <Typography variant="h5">
                    {isNaN(
                      ((this.state.closingRate * .01) * this.state.avgTicketPrice).toFixed(2)
                    ) ? ("  $" + 0) : ("  $" + ((this.state.closingRate * .01) * this.state.avgTicketPrice).toFixed(2))}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item sm={4} name="NetMarginValueOfLead">
            <Paper className={classes.outputPaper}>
              <Grid container className={classes.rowContainer}>
                <Grid item>
                  <Typography>
                    Net Margin Value of Lead
                    <br />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">
                    Actual Value X Gross Profit Margin
                    <br />
                  </Typography>
                </Grid>
                <br/>
                <Grid item>
                  <Typography variant="h5">
                    {isNaN(
                      (this.state.closingRate * .01 * this.state.avgTicketPrice * this.state.profitMargin * .01).toFixed(2)
                    ) ? ("  $" + 0) : ("  $" + (this.state.closingRate * .0001 * this.state.avgTicketPrice * this.state.profitMargin).toFixed(2))}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item sm={4} name="TotalMonthlyRevenue">
            <Paper className={classes.outputPaper}>
              <Grid container className={classes.rowContainer}>
                <Grid item>
                  <Typography>
                    Total Business Montly Revenue
                    <br/>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">
                    Gross Income For Business Owner
                    <br />
                  </Typography>
                </Grid>
                <br/>
                <Grid item>
                  <Typography variant="h5">
                    {isNaN(
                      (this.state.avgTicketPrice * this.state.closingRate * .01 * this.state.numLeadsMonthly).toFixed(2)
                    ) ? ("  $" + 0) : ("  $" + (this.state.avgTicketPrice * this.state.closingRate * .01 * this.state.numLeadsMonthly).toFixed(2))}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item sm={4} name="TotalMonthlyProfit">
            <Paper className={classes.outputPaper}>
              <Grid container className={classes.rowContainer}>
                <Grid item>
                  <Typography>
                    Total Monthly Profit
                    <br />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">
                    Before Cost of Lead Generation
                    <br />
                  </Typography>
                </Grid>
                <br />
                <Grid item>
                  <Typography variant="h5">
                    {isNaN(
                      (this.state.avgTicketPrice * this.state.closingRate * this.state.profitMargin * .0001 * this.state.numLeadsMonthly).toFixed(2)
                    ) ? ("  $" + 0) : ("  $" + (this.state.avgTicketPrice * this.state.closingRate * this.state.profitMargin * .0001 * this.state.numLeadsMonthly).toFixed(2))}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item sm={12} name="HowMuchToCharge">
            <Paper className={classes.outputPaper}>
              <Grid container className={classes.rowContainer}>
                <Grid item sm={12}>
                  <Typography>
                    How Much To Charge Per Lead?
                    <br />
                  </Typography>
                </Grid>
                <br />
                <Grid item sm={12}>
                  <Grid container direction='row'>
                    <Grid item sm={4}>
                      <Typography>
                        Low
                    <br />
                      </Typography>
                      <Typography variant="h5">
                        {isNaN(
                          (this.state.closingRate * .01 * this.state.avgTicketPrice * this.state.profitMargin * .01).toFixed(2)
                        ) ? ("  $" + 0) : ("  $" + (this.state.closingRate * .01 * this.state.avgTicketPrice * this.state.profitMargin * .01 * low).toFixed(2))}
                      </Typography>
                    </Grid>
                    <Grid item sm={4}>
                      <Typography>
                        Mid
                    <br />
                      </Typography>
                      <Typography variant="h5">
                        {isNaN(
                          (this.state.closingRate * .01 * this.state.avgTicketPrice * this.state.profitMargin * .01).toFixed(2)
                        ) ? ("  $" + 0) : ("  $" + (this.state.closingRate * .01 * this.state.avgTicketPrice * this.state.profitMargin * .01 * mid).toFixed(2))}
                      </Typography>
                    </Grid>
                    <Grid item sm={4}>
                      <Typography>
                        High
                    <br />
                      </Typography>
                      <Typography variant="h5">
                        {isNaN(
                          (this.state.closingRate * .01 * this.state.avgTicketPrice * this.state.profitMargin * .01).toFixed(2)
                        ) ? ("  $" + 0) : ("  $" + (this.state.closingRate * .0001 * this.state.avgTicketPrice * this.state.profitMargin * high).toFixed(2))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item sm={12} name="WhatWouldMonthlyProfitBe">
            <Paper className={classes.outputPaper}>
              <Grid container className={classes.rowContainer}>
                <Grid item sm={12}>
                  <Typography>
                    What would the business owner's monthly profit be?
                    <br />
                  </Typography>
                </Grid>
                <br />
                <Grid item sm={12}>
                  <Grid container direction='row'>
                    <Grid item sm={4}>
                      <Typography>
                        Low
                    <br />
                      </Typography>
                      <Typography variant="h5">
                        {isNaN(
                          (this.state.avgTicketPrice * this.state.closingRate * this.state.profitMargin * .0001 * this.state.numLeadsMonthly).toFixed(2)
                        ) ? ("  $" + 0) : ("  $" + ((this.state.avgTicketPrice * this.state.closingRate * this.state.profitMargin * .0001 * this.state.numLeadsMonthly) * (1 - low)).toFixed(2))}
                      </Typography>
                    </Grid>
                    <Grid item sm={4}>
                      <Typography>
                        Mid
                    <br />
                      </Typography>
                      <Typography variant="h5">
                        {isNaN(
                          (this.state.avgTicketPrice * this.state.closingRate * this.state.profitMargin * .0001 * this.state.numLeadsMonthly).toFixed(2)
                        ) ? ("  $" + 0) : ("  $" + ((this.state.avgTicketPrice * this.state.closingRate * this.state.profitMargin * .0001 * this.state.numLeadsMonthly) * (1 - mid)).toFixed(2))}
                      </Typography>
                    </Grid>
                    <Grid item sm={4}>
                      <Typography>
                        High
                    <br />
                      </Typography>
                      <Typography variant="h5">
                        {isNaN(
                          ((this.state.avgTicketPrice * this.state.closingRate * this.state.profitMargin * .0001 * this.state.numLeadsMonthly)).toFixed(2)
                        ) ? ("  $" + 0) : ("  $" + ((this.state.avgTicketPrice * this.state.closingRate * this.state.profitMargin * .0001 * this.state.numLeadsMonthly) * (1 - high)).toFixed(2))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item sm={12} name="HowMuchToChargeAsFlatFee">
            <Paper className={classes.outputPaper}>
              <Grid container className={classes.rowContainer}>
                <Grid item sm={12}>
                  <Typography>
                    How Much To Charge As Montly Flat Fee?
                  <br />
                  </Typography>
                </Grid>
                <br />
                <Grid item sm={12}>
                  <Grid container direction='row'>
                    <Grid item sm={4}>
                      <Typography>
                        Low
                  <br />
                      </Typography>
                      <Typography variant="h5">
                        {isNaN(
                          (this.state.closingRate * .01 * this.state.avgTicketPrice * this.state.profitMargin * .01 * this.state.numLeadsMonthly).toFixed(2)
                        ) ? ("  $" + 0) : ("  $" + (this.state.closingRate * .01 * this.state.avgTicketPrice * this.state.profitMargin * .01 * low * this.state.numLeadsMonthly).toFixed(2))}
                      </Typography>
                    </Grid>
                    <Grid item sm={4}>
                      <Typography>
                        Mid
                  <br />
                      </Typography>
                      <Typography variant="h5">
                        {isNaN(
                          (this.state.closingRate * .01 * this.state.avgTicketPrice * this.state.profitMargin * .01 * this.state.numLeadsMonthly).toFixed(2)
                        ) ? ("  $" + 0) : ("  $" + (this.state.closingRate * .01 * this.state.avgTicketPrice * this.state.profitMargin * .01 * mid * this.state.numLeadsMonthly).toFixed(2))}                      </Typography>
                    </Grid>
                    <Grid item sm={4}>
                      <Typography>
                        High
                  <br />
                      </Typography>
                      <Typography variant="h5">
                        {isNaN(
                          (this.state.closingRate * .01 * this.state.avgTicketPrice * this.state.profitMargin * .01 * this.state.numLeadsMonthly).toFixed(2)
                        ) ? ("  $" + 0) : ("  $" + (this.state.closingRate * .01 * this.state.avgTicketPrice * this.state.profitMargin * .01 * high * this.state.numLeadsMonthly).toFixed(2))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

LeadGenCalculator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LeadGenCalculator);